<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcQualifiedChurchSpeakers }}</h1>
    </header>
    <!-- / Page Header -->

    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <!-- / Camp Selector -->

    <data-table :fields="fields" :listType="list_type" :i18n="translations.components" :items="qualifiedSpeakersAlter"
      :toggleMe="true" :addItems="addItems" :handleDeleteAction="false" :handleEditAction="false"
      :handleViewAction="false" :csvUrl="csvUrl" :pdfUrl="pdfUrl" :reportName="reportName"></data-table>
    <!-- / Data Tables -->

    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'qualified-speakers',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'camp_name', label: 'Camp', sortable: true },
        { key: 'presentation_media_flag_string', label: 'Media Qualified', sortable: true },
        {
          key: 'qualified_date',
          label: 'Date Qualified',
          sortable: true,
          formatter: (value, key, item) => {
            if (value !== null) {
              return value.slice(0, 10)
            } else return ''
          },
        },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'QualifiedChurchSpeakersExport',
    }
  },
  methods: {
    ...mapActions({
      getQualifiedSpeakers: 'directory/getQualifiedSpeakers',
      getMemberChurches: 'campModule/getMemberChurches',
      loadChurches: 'churchMinistry/loadChurches',
      loadQualifiedChurchSpeakersUrls: 'directory/loadQualifiedChurchSpeakersUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      upsertDinnerSpeakers: 'directory/upsertDinnerSpeakers',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.getMemberChurches(this.userOrgKey)
        await this.getQualifiedSpeakers(this.officerSelectStatekey.country_state)
        await this.loadQualifiedChurchSpeakersUrls(this.officerSelectStatekey.country_state)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    addPresenter() {
      this.$router.push({
        name: this.isState ? 'membership-dinner-speakers-upsert' : 'membership-dinner-speakers-upsert-country',
      })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddMember
      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcCamp
      this.fields[2].label = this.translations.tcMediaQualified
      this.fields[3].label = this.translations.tcDateQualified
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('camp-select', 'data-table', 'data-table-member-churches'),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      csvUrl: 'directory/qualifiedChurchSpeakersCSVUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      officerSelectStatekey: 'user/officerToolbarSelected',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      qualifiedSpeakers: 'directory/qualifiedSpeakers',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
    }),
    qualifiedSpeakersAlter() {
      let qualifiedSpeaker = this.qualifiedSpeakers.map((x) => {
        const mediaQualified = `tc${x.presentation_media_flag_string}`
        const mediaQualifiedText = this.translations.hasOwnProperty(mediaQualified) ? this.translations[mediaQualified] : x.presentation_media_flag_string
        return {
          vin_ind_key: x.ind_key,
          name: x.name,
          sort_name: x.sort_name,
          qualified: x.qualified,
          ixa_key: x.ixa_key,
          ixo_key: x.ixo_key,
          qualified_date: x.qualified_date,
          presentation_media_flag: x.presentation_media_flag,
          camp_name: x.camp_name,
          ixa_note: x.ixo_note,
          ixa_scripture_blitz_date: x.ixa_scripture_blitz_date,
          ixa_scripture_blitz_title: x.ixa_scripture_blitz_title,
          ixa_start_date: x.ixa_start_date,
          ind_last_name: x.ind_last_name,
          ind_first_name: x.ind_first_name,
          presentation_media_flag_string: mediaQualifiedText,
        }
      })
      return qualifiedSpeaker
    },
    isState() {
      return this.$route.path.toLowerCase().includes('country') ? false : true
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
